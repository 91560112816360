import React, { useState, useRef, useEffect, Suspense } from 'react';
import { OrbitControls, useGLTF, Bounds } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { AnaglyphEffect } from 'three/examples/jsm/effects/AnaglyphEffect';
import { Button, Input } from 'antd';

function Model({ fileUrl, ...props }) {
  useGLTF.preload(fileUrl);
  const { nodes, materials } = useGLTF(fileUrl);
  console.log(nodes);
  console.log(materials);

  const geometry = Object.values(nodes).filter((node) => node.type === 'Mesh')[0].geometry;
  const material = Object.values(materials).filter((material) => material.type === 'MeshStandardMaterial')[0];

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.174}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            geometry={geometry}
            material={material}
            scale={286.365}
            position={[0, 0, 0]} // Adjusted depth to reduce crosstalk
          />
        </group>
      </group>
    </group>
  );
}

function AnaglyphRenderer({ isAnaglyph }) {
  const { gl, scene, camera, size } = useThree();
  const effectRef = useRef();
  const animationRef = useRef();

  useEffect(() => {
    if (isAnaglyph) {
      effectRef.current = new AnaglyphEffect(gl);
      effectRef.current.setSize(size.width, size.height);

      // Adjust separation to minimize crosstalk
      effectRef.current.separation = 0.8; // Calibrated value to reduce crosstalk

      const handleResize = () => {
        if (effectRef.current) {
          effectRef.current.setSize(window.innerWidth, window.innerHeight);
        }
      };
      window.addEventListener('resize', handleResize);

      const renderLoop = () => {
        if (effectRef.current) {
          effectRef.current.render(scene, camera);
          animationRef.current = requestAnimationFrame(renderLoop);
        }
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        window.removeEventListener('resize', handleResize);
        effectRef.current = null;
      };
    } else {
      const renderLoop = () => {
        gl.render(scene, camera);
        animationRef.current = requestAnimationFrame(renderLoop);
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [isAnaglyph, gl, scene, camera, size]);

  return null;
}

function RenderPage({ fileUrl = '/heart/scene.gltf' }) {
  const [isAnaglyph, setIsAnaglyph] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Input
        type="text"
        value={backgroundColor}
        onChange={handleColorChange}
        placeholder="Enter background color"
        style={{ marginBottom: 16 }}
      />
      <Button onClick={() => setIsAnaglyph((prev) => !prev)} style={{ marginBottom: 16 }}>
        {isAnaglyph ? 'Disable Anaglyph Effect' : 'Enable Anaglyph Effect'}
      </Button>
      <Canvas
        style={{ width: '100%', height: '120vh', border: '1px solid black', backgroundColor }}
        camera={{
          aspect: 1,
          fov: 40, // Moderate FOV to reduce crosstalk
          near: 0.1,
          far: 1000,
          position: [0, 0, 3], // Adjusted position to find optimal depth
        }}
      >
        <ambientLight intensity={1.2} />
        <Suspense fallback={null}>
          <Bounds fit clip margin={1.2}>
            <Model fileUrl={fileUrl} />
          </Bounds>
        </Suspense>
        <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} />
        <AnaglyphRenderer isAnaglyph={isAnaglyph} />
      </Canvas>
    </div>
  );
}

export default RenderPage;
