import React, { useState, useRef, useEffect, Suspense } from 'react';
import { OrbitControls, Bounds } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { AnaglyphEffect } from '../lib/NewAnaglyphEffect'; // 로컬 수정한 AnaglyphEffect
import { Button, Input, Slider } from 'antd';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';

function Model({ fileUrl, ...props }) {
  const { scene } = useLoader(GLTFLoader, fileUrl);
  return <primitive object={scene} {...props} />;
}

function AnaglyphRenderer({ isAnaglyph, separation }) {
  const { gl, scene, camera, size } = useThree();
  const effectRef = useRef();
  const animationRef = useRef();

  useEffect(() => {
    if (isAnaglyph) {
      effectRef.current = new AnaglyphEffect(gl);
      effectRef.current.setSize(size.width, size.height);

      const handleResize = () => {
        if (effectRef.current) {
          effectRef.current.setSize(window.innerWidth, window.innerHeight);
        }
      };
      window.addEventListener('resize', handleResize);

      const renderLoop = () => {
        if (effectRef.current) {
          // 렌더링 전에 separation 값을 eyeSep에 반영
          effectRef.current.stereo.eyeSep = separation;
          effectRef.current.render(scene, camera);
          animationRef.current = requestAnimationFrame(renderLoop);
        }
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        window.removeEventListener('resize', handleResize);
        effectRef.current = null;
      };
    } else {
      const renderLoop = () => {
        gl.render(scene, camera);
        animationRef.current = requestAnimationFrame(renderLoop);
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [isAnaglyph, gl, scene, camera, size, separation]);

  return null;
}

function RenderGlbPage({ fileUrl = '/starmap/starmap.glb' }) {
  console.log('target file:', fileUrl);
  const [isAnaglyph, setIsAnaglyph] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [separation, setSeparation] = useState(0.064); // StereoCamera 기본값을 참고하여 초기화

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleSeparationChange = (value) => {
    setSeparation(value);
  };

  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Input
        type="text"
        value={backgroundColor}
        onChange={handleColorChange}
        placeholder="Enter background color"
        style={{ marginBottom: 16 }}
      />
      <Button onClick={() => setIsAnaglyph((prev) => !prev)} style={{ marginBottom: 16 }}>
        {isAnaglyph ? 'Disable Anaglyph Effect' : 'Enable Anaglyph Effect'}
      </Button>
      {isAnaglyph && (
        <Slider
          min={0}
          max={0.2} // eyeSep 값은 일반적으로 작게(미터 단위) 잡습니다. 상황에 맞게 조정 가능
          step={0.001}
          value={separation}
          onChange={handleSeparationChange}
          style={{ marginBottom: 16 }}
        />
      )}
      <Canvas
        style={{ width: '100%', height: '120vh', border: '1px solid black', backgroundColor }}
        camera={{
          aspect: 1,
          fov: 40,
          near: 0.1,
          far: 1000,
          position: [0, 0, 10],
        }}
      >
        <ambientLight intensity={1.2} />
        <directionalLight position={[10, 10, 10]} intensity={1.5} />
        <Suspense fallback={null}>
          <Bounds fit clip margin={1.2}>
            <Model fileUrl={fileUrl} scale={[0.1, 0.1, 0.1]} position={[0, 0, 0]} />
          </Bounds>
        </Suspense>
        <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} />
        <AnaglyphRenderer isAnaglyph={isAnaglyph} separation={separation} />
      </Canvas>
    </div>
  );
}

export default RenderGlbPage;
