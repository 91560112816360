import React, { useEffect, useState } from 'react';

const GamePage = () => {
    
    return (
        <div>
      <h1>Dynamic HTML Rendering</h1>
      <iframe
        src="/pong/index.html" // public 폴더의 HTML 파일 경로
        width="1920px"
        height="1080px"
        style={{ border: "none" }}
      />
    </div>
    );
};

export default GamePage;