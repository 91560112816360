import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { AnaglyphEffect } from 'three/examples/jsm/effects/AnaglyphEffect';
import { Typography } from 'antd';

const { Title } = Typography;

function AnaglyphEffectComponent() {
  const { gl, scene, camera } = useThree();
  const effectRef = useRef();
  const animationRef = useRef();

  useEffect(() => {
    effectRef.current = new AnaglyphEffect(gl);
    effectRef.current.setSize(window.innerWidth, window.innerHeight);

    const handleResize = () => {
      if (effectRef.current) {
        effectRef.current.setSize(window.innerWidth, window.innerHeight);
      }
    };
    window.addEventListener('resize', handleResize);

    const onDocumentMouseMove = (event) => {
      const mouseX = (event.clientX - window.innerWidth / 2) / 10;
      const mouseY = (event.clientY - window.innerHeight / 2) / 10;
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY - camera.position.y) * 0.05;
      camera.lookAt(scene.position);
    };
    document.addEventListener('mousemove', onDocumentMouseMove, false);

    const renderLoop = () => {
      if (effectRef.current) {
        effectRef.current.render(scene, camera);
        animationRef.current = requestAnimationFrame(renderLoop);
      }
    };
    renderLoop();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousemove', onDocumentMouseMove);
      effectRef.current = null;
    };
  }, [gl, scene, camera]);

  return null;
}

function SphereScene() {
  const meshRef = useRef();

  useEffect(() => {
    const spheres = [];
    const textureLoader = new THREE.CubeTextureLoader();
    const path = "https://threejs.org/examples/textures/cube/pisa/";
    const format = '.png';
    const urls = [
      path + 'px' + format, path + 'nx' + format,
      path + 'py' + format, path + 'ny' + format,
      path + 'pz' + format, path + 'nz' + format
    ];
    const textureCube = textureLoader.load(urls);

    const geometry = new THREE.SphereGeometry(0.1, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff, envMap: textureCube });

    for (let i = 0; i < 100; i++) {
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.x = Math.random() * 10 - 5;
      mesh.position.y = Math.random() * 10 - 5;
      mesh.position.z = Math.random() * 10 - 5;
      mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 3 + 1;

      if (meshRef.current) {
        meshRef.current.add(mesh);
      }
      spheres.push(mesh);
    }

    const animateSpheres = () => {
      const timer = 0.0001 * Date.now();
      spheres.forEach((sphere, i) => {
        sphere.position.x = 5 * Math.cos(timer + i);
        sphere.position.y = 5 * Math.sin(timer + i * 1.1);
      });
      requestAnimationFrame(animateSpheres);
    };
    animateSpheres();

    return () => {
      if (meshRef.current) {
        meshRef.current.clear();
      }
    };
  }, []);

  return <group ref={meshRef} />;
}

function Background() {
  const { scene } = useThree();

  useEffect(() => {
    const textureLoader = new THREE.CubeTextureLoader();
    const path = "https://threejs.org/examples/textures/cube/pisa/";
    const format = '.png';
    const urls = [
      path + 'px' + format, path + 'nx' + format,
      path + 'py' + format, path + 'ny' + format,
      path + 'pz' + format, path + 'nz' + format
    ];
    const textureCube = textureLoader.load(urls);
    scene.background = textureCube;
  }, [scene]);

  return null;
}

function AnaglyphPage() {
  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Title>Anaglyph Three.js Example in React</Title>
      <Canvas style={{ width: '100%', height: '80vh', border: '1px solid black' }}>
        <ambientLight intensity={0.6} />
        <SphereScene />
        <Background />
        <AnaglyphEffectComponent />
      </Canvas>
    </div>
  );
}

export default AnaglyphPage;
