import React from 'react';
import { Layout } from 'antd';
import { Routes, Route } from 'react-router-dom';
import routes from './routes';
import Header from './components/Header';

const { Content } = Layout;

function App() {
  return (
    <Layout>
      <Header />
      <Content style={{ padding: '0 50px', marginTop: 64 }}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.component} />
          ))}
        </Routes>
      </Content>
    </Layout>
  );
}

export default App;
