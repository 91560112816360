import React from 'react';
import { useAtom } from 'jotai';
import { exampleAtom } from '../atoms/exampleAtom';
import { Typography, Button } from 'antd';

const { Title } = Typography;

function HomePage() {
  const [text, setText] = useAtom(exampleAtom);

  const handleRedirect = () => {
    window.location.href = 'https://paranduru.me/';
  };

  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Title>PARANDURU ME.</Title>
      <Title level={3}>Parandurume – Fly over the disruptive innovation to create Positive Impact</Title>
      <p>파란 두루미 랜더링 페이지에 오신것을 환영합니다!</p>
      <p>Welcome to the home page of PARANDURUME.!</p>
      <div style={{ marginTop: 24 }}>
        <Title level={2}>이 사이트는...</Title>
        <Title level={5}>This site is...</Title>
        <p>이 사이트는 다양한 3D 모델을 렌더링하고 시각화하는 데 중점을 둔 웹 애플리케이션입니다. GLTF 및 OBJ 형식의 파일을 지원하며, 사용자는 파일을 업로드하고 렌더링된 결과를 확인할 수 있습니다.</p>
        <p>This site is a web application focused on rendering and visualizing various 3D models. It supports GLTF and OBJ file formats, allowing users to upload files and view the rendered results.</p>
        <Button type="default" onClick={handleRedirect}>
          메인 홈페이지로 이동
        </Button>
      </div>
    </div>
  );
}

export default HomePage;