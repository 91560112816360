import React, { useState, useEffect, Suspense } from 'react';
import { List } from 'antd';
import { fetchFileList, buildAzPathUrl } from '../lib/azureBlobClient';
import RenderObjPage from './RenderObjPage';
import RenderGlbPage from './RenderGlbPage';
import { useLocation, useNavigate } from 'react-router-dom';

function NewRenderPage() {
  const requiredExt = ['obj', 'gltf', 'glb'];
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileList, setFileList] = useState([]);
  const [filteredFileList, setFilteredFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [mtlUrl, setMtlUrl] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleFileSelect = (file) => {
    const filename = file.split('/').pop();
    // 쿼리 스트링을 업데이트하여 파일 선택 반영 (파일 이름만 추가)
    navigate(`?filename=${filename}`);

    // 페이지를 완전히 새로고침하여 모든 상태를 초기화
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 0);
  };

  const loadModel = () => {
    if (selectedFile) {
      // 파일의 실제 경로 생성 (폴더와 파일명을 결합)
      const folderName = selectedFile.split('.')[0]; // 폴더 이름 추출 (파일명 앞의 부분 사용)
      const filePath = `${folderName}/${selectedFile}`;
      const url = buildAzPathUrl(filePath);
      setFileUrl(url);

      if (selectedFile.endsWith('.obj')) {
        const mtlFileName = selectedFile.replace('.obj', '.mtl');
        const mtlFileExists = fileList.includes(`${folderName}/${mtlFileName}`);
        if (mtlFileExists) {
          const mtlUrl = buildAzPathUrl(`${folderName}/${mtlFileName}`);
          setMtlUrl(mtlUrl);
        }
      }
    }
  };

  const loadFileList = async () => {
    const files = await fetchFileList();
    setFileList(files);
    const displayFiles = files.reduce((acc, file) => {
      if (requiredExt.some(ext => file.endsWith(`.${ext}`))) {
        acc.push({ renderTarget: file });
      }
      return acc;
    }, []);
    setFilteredFileList(displayFiles);
  };

  useEffect(() => {
    loadFileList();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filenamePath = params.get('filename');

    if (filenamePath) {
      // 쿼리 스트링에서 파일명을 가져와 설정 (폴더 정보는 이 시점에서는 알 필요 없음)
      setSelectedFile(filenamePath);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      selectedFile &&
      requiredExt.some(ext => selectedFile.endsWith(`.${ext}`))
    ) {
      if (selectedFile.endsWith('.obj')) {
        setFileType('obj');
      } else if (selectedFile.endsWith('.gltf') || selectedFile.endsWith('.glb')) {
        setFileType('gltf');
      }

      loadModel();
    }
  }, [selectedFile, fileList]);

  return (
    <div style={{ display: 'flex', padding: 24, minHeight: 380 }}>
      <div style={{ flex: 1, marginRight: 16 }}>
        <List
          header={<div>File List</div>}
          bordered
          dataSource={filteredFileList}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                if (
                  item.renderTarget.endsWith('.obj') 
                  || item.renderTarget.endsWith('.gltf')
                  || item.renderTarget.endsWith('.glb')
                ) {
                  handleFileSelect(item.renderTarget);
                }
              }}
              style={{
                fontWeight:
                  item.renderTarget.endsWith('.obj') 
                  || item.renderTarget.endsWith('.gltf')
                  || item.renderTarget.endsWith('.glb')
                    ? 'bold'
                    : 'normal',
                color:
                  item.renderTarget.endsWith('.obj') 
                  || item.renderTarget.endsWith('.gltf')
                  || item.renderTarget.endsWith('.glb')
                    ? 'blue'
                    : 'black',
                cursor: 'pointer',
              }}
            >
              {item.renderTarget.split('/').pop()}
            </List.Item>
          )}
          style={{ marginBottom: 16 }}
        />
      </div>
      <div style={{ flex: 3 }}>
        <Suspense fallback={null}>
          {selectedFile && fileUrl ? (
            fileType === 'gltf' || fileType === 'glb' ? (
              <RenderGlbPage key={`${fileUrl}`} fileUrl={fileUrl} />
            ) : fileType === 'obj' && mtlUrl ? (
              <RenderObjPage key={`${fileUrl}`} fileUrl={fileUrl} mtlUrl={mtlUrl} />
            ) : (
              <RenderObjPage key={`${fileUrl}`} fileUrl={fileUrl} />
            )
          ) : (
            <div>Select file please.</div>
          )}
        </Suspense>
      </div>
    </div>
  );
}

export default NewRenderPage;
