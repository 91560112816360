import { BlobServiceClient } from '@azure/storage-blob';

const connectingKey = 'https://durumerender.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-26T20:52:35Z&st=2024-10-26T12:52:35Z&spr=https&sig=aXZhvA1EMNWDmFHllDfeE6458y3Uh4aa0d%2BEqBWFOXI%3D';
const blobServiceClient = new BlobServiceClient(connectingKey);
const containerClient = blobServiceClient.getContainerClient('tmp');

export default class AzureBlobClient {
  constructor() {
    this.requiredFileTypes = [".obj", ".gltf", ".glb"];
  }

  handleFileUpload = (files, onUploadComplete) => {
    const filesWithContent = files.map(file => ({
      name: file.name,
      path: file.path,
      fullPath: file.fullPath,
      size: file.size,
      content: new Blob([file.content]), // Blob으로 변환
    }));

    const hasRequiredFile = filesWithContent.some(file =>
      file.name && this.requiredFileTypes.some(ext => file.name.endsWith(ext))
    );

    if (!hasRequiredFile) {
      alert(`Check!!: ${this.requiredFileTypes.join(' or ')} files are required.`);
      return;
    }

    const targetFile = filesWithContent.find(file => file.name && this.requiredFileTypes.some(ext => file.name.endsWith(ext)));
    const targetPath = targetFile.name.substring(0, targetFile.name.lastIndexOf('.'));

    const azBlobFilePath = (filename) => `${targetPath}/${filename}`;

    let uploadCounter = 0;
    const totalFilesCount = filesWithContent.length;

    filesWithContent.forEach((file) => {
      const realBlobPath = azBlobFilePath(file.fullPath);
      const blockBlobClient = containerClient.getBlockBlobClient(realBlobPath);

      blockBlobClient.upload(file.content, file.size)
        .then((res) => {
          uploadCounter++;
          console.log(`${file.name} - (${uploadCounter}/${totalFilesCount}) - Upload successfully - az_reqID:`, res.requestId);

          if (totalFilesCount === uploadCounter) {
            console.log('All files uploaded successfully');
            if (onUploadComplete) onUploadComplete();
          }
        });
    });

    return targetPath;
  }
}

export function targetAzPath(filename) {
  return filename.substring(0, filename.lastIndexOf('.'));
}

export function buildAzPathUrl(filename) {
  return `https://durumerender.blob.core.windows.net/tmp/${filename}`;
}

export async function fetchFileList() {
  const fileList = [];
  for await (const blob of containerClient.listBlobsFlat()) {
    fileList.push(blob.name);
  }

  return fileList;
}
