import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import * as CANNON from 'cannon';
import 'aframe';
import 'aframe-ar';

const CubesWith3DCursorPage = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    // Custom Anaglyph Effect
    const anaglyphShader = {
      uniforms: {
        "tLeft": { value: null },
        "tRight": { value: null }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform sampler2D tLeft;
        uniform sampler2D tRight;
        varying vec2 vUv;
        void main() {
          vec4 leftColor = texture2D(tLeft, vUv);
          vec4 rightColor = texture2D(tRight, vUv);
          gl_FragColor = vec4(leftColor.r, rightColor.g, rightColor.b, 1.0);
        }
      `
    };

    const leftRenderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);
    const rightRenderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);
    const anaglyphMaterial = new THREE.ShaderMaterial(anaglyphShader);
    const anaglyphScene = new THREE.Scene();
    const anaglyphCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const anaglyphMesh = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), anaglyphMaterial);
    anaglyphScene.add(anaglyphMesh);

    const world = new CANNON.World();
    world.gravity.set(0, -9.82, 0);

    const groundShape = new CANNON.Plane();
    const groundBody = new CANNON.Body({ mass: 0 });
    groundBody.addShape(groundShape);
    groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    world.addBody(groundBody);

    const groundGeometry = new THREE.PlaneGeometry(20, 20);
    const groundMaterial = new THREE.MeshPhongMaterial({ color: 0xcccccc });
    const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial);
    groundMesh.rotation.x = -Math.PI / 2;
    scene.add(groundMesh);

    const cubes = [];
    const cubeShape = new CANNON.Box(new CANNON.Vec3(0.5, 0.5, 0.5));
    const cubeGeometry = new THREE.BoxGeometry(1, 1, 1);

    for (let i = 0; i < 20; i++) {
      const cubeMaterial = new THREE.MeshPhongMaterial({
        color: Math.random() * 0xffffff,
        shininess: 100
      });
      const cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial);
      scene.add(cubeMesh);

      const cubeBody = new CANNON.Body({
        mass: 1,
        shape: cubeShape,
        position: new CANNON.Vec3(
          Math.random() * 10 - 5,
          Math.random() * 10 + 5,
          Math.random() * 10 - 5
        )
      });
      world.addBody(cubeBody);

      cubes.push({ mesh: cubeMesh, body: cubeBody });
    }

    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    camera.position.set(0, 10, 20);
    camera.lookAt(0, 0, 0);

    // 3D Cursor
    const cursorGeometry = new THREE.SphereGeometry(0.1, 32, 32);
    const cursorMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const cursor = new THREE.Mesh(cursorGeometry, cursorMaterial);
    scene.add(cursor);

    let isRotating = false;
    let previousMousePosition = { x: 0, y: 0 };
    const spherical = new THREE.Spherical().setFromVector3(camera.position);

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let selectedCube = null;
    let jointBody = null;
    let constraint = null;

    let cursorDepth = 10; // Initial depth of the cursor

    document.addEventListener('mousedown', (e) => {
      if (e.button === 1) { // Middle mouse button
        isRotating = true;
      } else if (e.button === 0) { // Left mouse button
        // Use the cursor position to check for intersections
        raycaster.set(camera.position, cursor.position.sub(camera.position).normalize());
        const intersects = raycaster.intersectObjects(cubes.map(cube => cube.mesh));
        if (intersects.length > 0) {
          selectedCube = cubes.find(cube => cube.mesh === intersects[0].object);
          jointBody = new CANNON.Body({ mass: 0 });
          world.addBody(jointBody);

          const pivotA = new CANNON.Vec3().copy(cursor.position);
          const pivotB = new CANNON.Vec3().copy(intersects[0].point).vsub(selectedCube.body.position);
          constraint = new CANNON.PointToPointConstraint(selectedCube.body, pivotB, jointBody, pivotA);
          world.addConstraint(constraint);
        }
      }
    });

    document.addEventListener('mouseup', () => {
      isRotating = false;
      if (constraint) {
        world.removeConstraint(constraint);
        world.removeBody(jointBody);
        constraint = null;
        jointBody = null;
        selectedCube = null;
      }
    });

    document.addEventListener('mousemove', (e) => {
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

      if (isRotating) {
        const deltaMove = {
          x: e.clientX - previousMousePosition.x,
          y: e.clientY - previousMousePosition.y
        };
        spherical.theta -= deltaMove.x * 0.01;
        spherical.phi -= deltaMove.y * 0.01;
        spherical.phi = Math.max(0.1, Math.min(Math.PI - 0.1, spherical.phi));
        camera.position.setFromSpherical(spherical);
        camera.lookAt(0, 0, 0);
      }

      // Update cursor position
      raycaster.setFromCamera(mouse, camera);
      const cursorPosition = new THREE.Vector3();
      raycaster.ray.at(cursorDepth, cursorPosition);
      cursor.position.copy(cursorPosition);

      if (constraint) {
        jointBody.position.copy(cursor.position);
      }

      previousMousePosition = { x: e.clientX, y: e.clientY };
    });

    document.addEventListener('wheel', (e) => {
      cursorDepth += e.deltaY * 0.01;
      cursorDepth = Math.max(1, Math.min(20, cursorDepth)); // Clamp depth between 1 and 20
    });

    function renderAnaglyph() {
      const parallax = parseFloat(document.getElementById('parallax-slider').value * 10);
      const originalPosition = camera.position.clone();

      // Render left eye (red channel)
      camera.position.sub(new THREE.Vector3(parallax, 0, 0));
      renderer.setRenderTarget(leftRenderTarget);
      renderer.render(scene, camera);

      // Render right eye (cyan channels)
      camera.position.copy(originalPosition).add(new THREE.Vector3(parallax, 0, 0));
      renderer.setRenderTarget(rightRenderTarget);
      renderer.render(scene, camera);

      // Reset camera position
      camera.position.copy(originalPosition);

      // Render anaglyph
      anaglyphMaterial.uniforms.tLeft.value = leftRenderTarget.texture;
      anaglyphMaterial.uniforms.tRight.value = rightRenderTarget.texture;
      renderer.setRenderTarget(null);
      renderer.render(anaglyphScene, anaglyphCamera);
    }

    function animate() {
      requestAnimationFrame(animate);
      world.step(1 / 60);
      cubes.forEach(cube => {
        cube.mesh.position.copy(cube.body.position);
        cube.mesh.quaternion.copy(cube.body.quaternion);
      });

      const enableAnaglyphCheckbox = document.getElementById('enable-anaglyph');
      if (enableAnaglyphCheckbox && enableAnaglyphCheckbox.checked) {
        renderAnaglyph();
      } else {
        renderer.render(scene, camera);
      }
    }

    animate();

    window.addEventListener('resize', () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      leftRenderTarget.setSize(window.innerWidth, window.innerHeight);
      rightRenderTarget.setSize(window.innerWidth, window.innerHeight);
    });

    // AR
    const coordinatesDiv = document.getElementById('coordinates');
    const marker = document.querySelector('a-marker');

    marker.addEventListener('markerFound', () => {
      coordinatesDiv.textContent = 'Marker detected';
    });

    marker.addEventListener('markerLost', () => {
      coordinatesDiv.textContent = 'Marker not detected';
    });

    function updateCoordinates() {
      if (marker && marker.object3D && marker.object3D.visible) {
        const position = marker.object3D.position;
        const rotation = marker.object3D.rotation;
        coordinatesDiv.textContent = `
          Position:
          X: ${position.x.toFixed(2)},
          Y: ${position.y.toFixed(2)},
          Z: ${position.z.toFixed(2)}
          Rotation:
          X: ${THREE.MathUtils.radToDeg(rotation.x).toFixed(2)}°,
          Y: ${THREE.MathUtils.radToDeg(rotation.y).toFixed(2)}°,
          Z: ${THREE.MathUtils.radToDeg(rotation.z).toFixed(2)}°
        `;

        const cursorPosition = new THREE.Vector3();
        cursorPosition.x = -10 * position.x;
        cursorPosition.y = position.y * 10;
        cursorPosition.z = (position.z + 5) * -10;
        cursor.position.copy(cursorPosition);
      }
      requestAnimationFrame(updateCoordinates);
    }

    updateCoordinates();

    return () => {
      container.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100vh' }}>
      <div id="controls">
        <label>
          <input type="checkbox" id="enable-anaglyph" /> Enable Anaglyph 3D
        </label>
        <br />
        <label htmlFor="parallax-slider">Parallax: </label>
        <input type="range" id="parallax-slider" min="0" max="0.1" step="0.001" defaultValue="0.05" />
        <div id="coordinates">Marker not detected</div>
      </div>
      <div id="AR-frame" style={{ visibility: 'hidden', display: 'none' }}>
        <a-scene embedded arjs="sourceType: webcam; debugUIEnabled: false;">
          <a-marker preset="kanji">
            <a-box position="0 0.5 0" material="color: red;"></a-box>
          </a-marker>
          <a-entity camera></a-entity>
        </a-scene>
      </div>
    </div>
  );
};

export default CubesWith3DCursorPage;