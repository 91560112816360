import React from 'react';
import { Typography, Button } from 'antd';

const { Title } = Typography;

function AboutPage() {
  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Title>About Page</Title>
      <p>우리 팀을 만나보세요!</p>
      <p>Learn more about our team and mission.</p>
      <Button type="primary" href="https://paranduru.me/#TeamMember" target="_blank" style={{ marginTop: 16 }}>
        Meet Our Team
      </Button>
    </div>
  );
}

export default AboutPage;
