import React from 'react';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { Header } = Layout;

function AppHeader() {
  const location = useLocation();

  return (
    <Header style={{ backgroundColor: '#0073e6', display: 'flex', alignItems: 'center' }}>
          <div style={{ color: '#fff', fontWeight: 'bold', fontSize: '24px', marginRight: 'auto' }}>
            <img src="https://i0.wp.com/paranduru.me/wp-content/uploads/2021/09/parandurume-logo-2x2-1.png?w=76&ssl=1
" alt="Parandurume" style={{ height: '60px', marginRight: '16px' }} />
            Parandurume
          </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[location.pathname]}
        items={[
          { key: '/', label: <Link to="/">Home</Link> },

          { key: '/new-render', label: <Link to="/new-render">New Render</Link> },
          { key: '/files', label: <Link to="/files">File Upload</Link> },
          { key: '/game', label: <Link to="/game">Game</Link> },
          { key: '/render-glb', label: <Link to="/render-glb">Render GLB</Link> },
          { key: '/render', label: <Link to="/render">Render GLTF</Link> },
          { key: '/anaglyph', label: <Link to="/anaglyph">Anaglyph Example</Link> },
          { key: '/render-obj', label: <Link to="/render-obj">Render OBJ</Link> },
          { key: '/cube', label: <Link to="/cube">Cube</Link> },

          { key: '/about', label: <Link to="/about">About</Link> },
        ]}
      />
    </Header>
  );
}

export default AppHeader;
