import React from 'react';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import RenderPage from './pages/RenderPage';
import RenderObjPage from './pages/RenderObjPage';
import FileUploadPage from './pages/FileUploadPage';
import NewRenderPage from './pages/NewRenderPage';
import AnaglyphExamplePage from './pages/AnaglyphExamplePage';
import CubeWith3dCursorPage from './pages/CubeWith3dCursorPage';
import GamePage from './pages/GamePage';
import RenderGlbPage from './pages/RenderGlbPage';

const routes = [
  { path: '/', component: <HomePage /> },
  { path: '/about', component: <AboutPage /> },
  { path: '/anaglyph', component: <AnaglyphExamplePage /> },
  { path: '/render', component: <RenderPage /> },
  { path: '/render-obj', component: <RenderObjPage /> },
  { path: '/files', component: <FileUploadPage /> },
  { path: '/new-render', component: <NewRenderPage /> },
  { path: '/cube', component: <CubeWith3dCursorPage /> },
  { path: '/game', component: <GamePage /> },
  { path: '/render-glb', component: <RenderGlbPage /> },
];

export default routes;
