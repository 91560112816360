import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { AnaglyphEffect } from 'three/examples/jsm/effects/AnaglyphEffect';
import { Typography, Button, Slider } from 'antd';

const { Title } = Typography;

function ObjModel({ fileUrl = '/male02/male02.obj', mtlUrl = '/male02/male02.mtl' }) {
  const meshRef = useRef();
  const { camera } = useThree();

  useEffect(() => {
    if (mtlUrl) {
      // Load with MTL if mtlUrl is provided
      const mtlLoader = new MTLLoader();
      mtlLoader.load(
        mtlUrl,
        (materials) => {
          materials.preload();
          const loader = new OBJLoader();
          loader.setMaterials(materials);
          loadObj(loader);
        },
        undefined,
        (error) => {
          console.log('An error happened with MTL loading:', error);
          const loader = new OBJLoader();
          loadObj(loader); // Load OBJ without materials if MTL fails
        }
      );
    } else {
      // Load OBJ directly if no MTL
      const loader = new OBJLoader();
      loadObj(loader);
    }

    function loadObj(loader) {
      loader.load(
        fileUrl,
        (loadedObj) => {
          if (meshRef.current) {
            meshRef.current.clear();

            // Calculate the bounding box to determine scale and center
            const box = new THREE.Box3().setFromObject(loadedObj);
            const size = new THREE.Vector3();
            box.getSize(size);
            const center = new THREE.Vector3();
            box.getCenter(center);

            // Set scale to fit the object within the view
            const maxDimension = Math.max(size.x, size.y, size.z);
            const scaleFactor = 0.5 / maxDimension;

            loadedObj.scale.set(scaleFactor, scaleFactor, scaleFactor);
            loadedObj.position.sub(center.multiplyScalar(scaleFactor));

            // Update camera to fit the object well
            const boundingSphere = box.getBoundingSphere(new THREE.Sphere());
            const distance = boundingSphere.radius * scaleFactor * 2.5;
            camera.position.set(0, boundingSphere.radius * scaleFactor, distance);
            camera.lookAt(0, 0, 0);

            // Add the loaded object to the scene
            meshRef.current.add(loadedObj);
          }
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
        },
        (error) => {
          console.log('An error happened while loading OBJ:', error);
        }
      );
    }

    return () => {
      if (meshRef.current) {
        meshRef.current.clear();
      }
    };
  }, [fileUrl, mtlUrl, camera]);

  return <group ref={meshRef} />;
}

function AnaglyphRenderer({ isAnaglyph }) {
  const { gl, scene, camera, size } = useThree();
  const effectRef = useRef();
  const animationRef = useRef();

  useEffect(() => {
    if (isAnaglyph) {
      effectRef.current = new AnaglyphEffect(gl);
      effectRef.current.setSize(size.width, size.height);

      const handleResize = () => {
        if (effectRef.current) {
          effectRef.current.setSize(window.innerWidth, window.innerHeight);
        }
      };
      window.addEventListener('resize', handleResize);

      const renderLoop = () => {
        if (effectRef.current) {
          effectRef.current.render(scene, camera);
          animationRef.current = requestAnimationFrame(renderLoop);
        }
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        window.removeEventListener('resize', handleResize);
        effectRef.current = null;
      };
    } else {
      const renderLoop = () => {
        gl.render(scene, camera);
        animationRef.current = requestAnimationFrame(renderLoop);
      };
      renderLoop();

      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
      };
    }
  }, [isAnaglyph, gl, scene, camera, size]);

  return null;
}

function RenderObjPage({ fileUrl, mtlUrl }) {
  const [isAnaglyph, setIsAnaglyph] = useState(false);
  const [fov, setFov] = useState(55);

  const handleFovChange = (value) => {
    setFov(value);
  };

  return (
    <div style={{ padding: 24, minHeight: 380 }}>
      <Title>Render OBJ Model</Title>
      <Button onClick={() => setIsAnaglyph((prev) => !prev)} style={{ marginBottom: 16 }}>
        {isAnaglyph ? 'Disable Anaglyph Effect' : 'Enable Anaglyph Effect'}
      </Button>
      <div style={{ marginBottom: 16 }}>
        <Title level={5}>Field of View (FOV): {fov}</Title>
        <Slider
          min={30}
          max={120}
          value={fov}
          onChange={handleFovChange}
          style={{ width: 300 }}
        />
      </div>
      <Canvas
        style={{ width: '100%', height: '80vh', border: '1px solid black' }}
        camera={{ position: [0, 5, 20], fov: fov }}
        onCreated={({ camera }) => {
          camera.fov = fov;
          camera.updateProjectionMatrix();
        }}
      >
        <ambientLight intensity={0.6} />
        <directionalLight position={[10, 10, 5]} intensity={1.2} castShadow />
        <ObjModel fileUrl={fileUrl} mtlUrl={mtlUrl} />
        <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} zoomSpeed={0.5} />
        <AnaglyphRenderer isAnaglyph={isAnaglyph} />
      </Canvas>
    </div>
  );
}

export default RenderObjPage;
